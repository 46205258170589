@import 'Styles/includes';

/**
*
* CardImageText
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardImageText {
    $root: &;
    background-color: white;

    width: 100%;

    &--Slim {
        @include media(md) {
            max-width: 756px;
            margin: 0 auto;
        }
    }

    &--Horizontal {
        display: flex;
        flex-direction: column;
        //align-items: center;

        @include media(md) {
            border-radius: 4px;
            overflow: hidden;
            flex-direction: row;
            box-shadow: 0 4px 16px 0 rgba(black, 0.2);
        }
    }

    &--Reverse {
        @include media(md) {
            flex-direction: row-reverse;
        }
    }

    // &__Tag {
    //     font-size: 1.6rem;
    //     font-weight: 700;
    //     font-weight: normal;
    //     text-transform: uppercase;
    //     margin-bottom: 5px;
    //     line-height: 1.5;
    //     margin-bottom: 8px;
    //     letter-spacing: 0.4px;

    //     @include media(lg) {
    //         font-size: 1.8rem;
    //         margin-bottom: 8px;
    //     }
    // }

    &__Header {
        padding-bottom: 16px;
        margin-bottom: 24px;
        border-bottom: 1px solid $gray-20;
        text-align: center;

        @include media(md) {
            max-width: 75%;
            padding-bottom: 32px;
            margin: 0 auto 48px auto;
        }
    }

    &__ImageWrapper {
        width: 100%;
        margin-bottom: 24px;
        overflow: hidden;
        position: relative;

        @include media(md) {
            margin-bottom: 0;
            width: 50%;

            #{$root}--IncreaseImageSize & {
                width: 65%;
            }
        }

        #{$root}--Wide & {
            width: 100%;
            background-color: transparent;
        }
    }

    // &__ImageBlur {
    //     background-position: center center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     position: absolute;
    //     z-index: -1;
    //     filter: blur(2px);
    //     transform: scale(1.5);
    //     width: 100%;
    //     height: 100%;
    //     padding-bottom: 56.25%;

    //     &::after {
    //         content: '';
    //         width: 100%;
    //         position: absolute;
    //         height: 100%;
    //         background: black;
    //         opacity: 0.25;
    //     }
    // }

    &__Image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        padding-bottom: 56.25%;

        @include media(md) {
            padding-bottom: 0;
        }

        #{$root}--Contain & {
            background-size: contain;

            @include media(md) {
                background-position: left center;
                padding-bottom: 56.25%;
            }
        }

        #{$root}--Contain#{$root}--Reverse & {
            @include media(md) {
                background-position: right center;
            }
        }

        #{$root}--Wide & {
            padding-bottom: 56.25%;

            @include media(md) {
                background-position: center center !important;
            }
        }
    }

    &__Image {
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__Content {
        width: 100%;
        height: 100%;
        background: white;

        #{$root}--Wide & {
            display: none;
        }

        @include media(md) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            min-height: 320px;
            padding: 36px 64px;
            width: 50%;
            align-items: flex-start;
            text-align: left;

            #{$root}--IncreaseImageSize & {
                width: 35%;
                padding: 36px 46px;
            }
        }
    }

    &__Title {
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: 0.2px;
        color: $text;
        margin-bottom: 8px;

        #{$root}--Wide & {
            @include media(lg) {
                font-size: 3.6rem;
            }
        }
    }
}
